.App {
  text-align: center;
  background-image: url('Images/background.jpg');
  background-color: #010203;
  background-repeat: no-repeat;
  background-size: cover;
}

.App::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.75);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* After React */

a {
  color: inherit;
}

a:visited {
  color: inherit
}

.Loading {
  position: relative;
  color: #ffffff;  
  line-height: 0.9;
  text-align: center;
}

.LoadHeader {
  color: white;
  font-family: 'Open Sans', sans-serif;
  animation: fadeIn 5s;
}

.LoadSubtitle {
  color: white;
  font-family: 'Montserrat', sans-serif;
  animation: fadeIn 10s;
}

.SocialBar {
  animation: fadeIn 15s;
}

.Icon {
  padding-left: 5px;
  padding-right: 5px;
  transition: transform 1s;
}

.Icon:hover {
  transform: scale(1.5);
}

/* Additions */

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Open+Sans:wght@300;400&display=swap');